import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyANp-yUGh9AwjJr2gxJ6Kodc0J8GCODfZo",
  authDomain: "village-square-test.firebaseapp.com",
  projectId: "village-square-test",
  storageBucket: "village-square-test.appspot.com",
  messagingSenderId: "820072225286",
  appId: "1:820072225286:web:f7e985ee5e712607d70187",
  measurementId: "G-W985JZLBS6",
};

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// Add the public key generated from the console here.

// firebase utils
const messaging = firebase.messaging()

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
messaging.getToken({ vapidKey: 'BD6auiHm8YCff2yEuuexerqZhhA3PfpTefNjf6iV_x4yxLQri5cp3XKuYc7QPQ6zOgzAgAZzCYY-NaDCjRZBa_s' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    //Save this token for each user app. Call them back for use
    // ...
    console.log(currentToken)
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
})

const db = firebase.firestore()
const field = firebase.firestore.FieldValue
const auth = firebase.auth()
const recaptcha = firebase.auth.RecaptchaVerifier
const fStore = firebase.storage()
const currentUser = auth.currentUser

export default {
  db,
  auth,
  field,
  currentUser,
  fStore,
  recaptcha,
  messaging
}